:root {
  --white-text: #fff;
  --grey-text: #909090;
  --primary-red: #F23645;
  --green-text: #22AB94;
  --yellow-text: #E9C713;
  --primary-purple: #6A0BFF;
}

.white-text {
  color: var(--white-text) !important;
}

.grey-text {
  color: var(--grey-text);
}

.red-text {
  color: var(--primary-red);
}

.green-text {
  color: var(--green-text);
}

.yellow-text {
  color: var(--yellow-text);
}

.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding .15s);
}

.sc-aXZVg {
  width: 100% !important;
  max-width: 100% !important;
  margin-top: 5px;
  border: 2px dashed var(--primary-purple) !important;
}

.sc-aXZVg svg {
  color: var(--primary-purple) !important;
}

.thumbnail-preview-container,
.image-preview-container {
  position: relative;
  width: 250px;
  height: 250px;
  border: 2px dotted rgb(205, 204, 204);
  margin-top: 30px;
}

.image-preview-container {
  margin: 30px 10px 10px 0px !important;
}

.thumbnail-preview-container svg,
.image-preview-container svg {
  position: absolute;
  top: -9px;
  right: -6px;
  background-color: red;
  border-radius: 20px;
  color: #fff;
  width: 70px;
}

.image-container {
  display: flex;
}

.thumbnail-image-prview {
  padding: 4px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-prview {
  padding: 4px;
  height: 100%;
  width: 100%;
  object-fit: contain !important;
}

.sidebar .sidebar-nav::-webkit-scrollbar-track {
  border: 1px solid #000;
  padding: 2px 0;
  background-color: #404040;
}

.sidebar .sidebar-nav::-webkit-scrollbar {
  width: 10px;
}

.sidebar .sidebar-nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #737272;
  border: 1px solid #000;
}

::-webkit-scrollbar-track {
  border: 1px solid #000;
  padding: 2px 0;
  background-color: #404040;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #737272;
  border: 1px solid #000;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
  z-index: 11111;
}

.ReactModal__Content {
  /* padding: 0 !important; */
  background-color: #181818 !important;
  border: none !important;
  width: 90%;
  border-radius: 15px !important;
}

.ReactModal__Content .btn-default {
  color: var(--white-text) !important;
}

/* Header and SideNav CSS */
.wrapper {
  max-height: 100vh;
  overflow: hidden;
  position: fixed;
  width: 100vw;
  right: 0;
}

.wrapper .body {
  height: 90vh;
  overflow-y: scroll;
  padding-bottom: 30px;
  padding-top: 20px;
  background: #0B0B0B url(./assets/images/background.png);
  background-size: cover;
}

.header.header-sticky {
  padding: 0.628rem !important;
}

.header.header-sticky .pi {
  font-size: 1.55rem;
}

.bg-light {
  background-color: #181818 !important;
}

@media screen and (max-width: 779px) {
  .header.header-sticky .pi {
    font-size: 1.15rem;
  }
}

.wrapper .header,
.sidebar .sidebar-brand {
  background: #181818;
  color: #fff;
  border: none;
}

.sidebar .sidebar-brand img {
  line-height: .8;
  margin-top: -2.5px;
  width: 90px;
  padding: 5px;
}

.wrapper .header a,
.wrapper .header button {
  color: #fff !important;
}

.sidebar .sidebar-nav {
  background: #0B0B0B url(./assets/images/background.png);
  background-size: cover;
  background-position: center;
}

.sidebar .sidebar-nav .simplebar-wrapper {
  margin-top: 20px !important;
  background-color: #181818;
  border-radius: 0px 15px 0px 0px;
  min-height: 98vh;
}

.sidebar .sidebar-nav .simplebar-wrapper .simplebar-mask {
  padding-top: 20px;
  margin-right: 20px;
}

.sidebar-nav .nav-group.show {
  background-color: transparent !important;
}

.sidebar-nav .nav-link:hover {
  color: var(--white-text) !important;
  background: var(--primary-purple) !important;
  border-radius: 0px 5px 5px 0px;
}

.sidebar-nav .nav-link.active {
  color: var(--white-text) !important;
  background: var(--primary-purple) !important;
  border-radius: 0px 5px 5px 0px;
}

.sidebar-nav .nav-link {
  font-weight: 500 !important;
  font-size: 15px !important;
  color: var(--white-text) !important;
  padding: 12px 12px 12px 40px !important;
}

.sidebar-nav .nav-group .nav-group-items {
  padding-left: 15px !important;
}

.sidebar-nav .nav-group .nav-group-items .nav-group .nav-group-items .nav-item .nav-link {
  padding-left: 40px !important;
}

.simplebar-placeholder {
  display: none !important;
}

@media (max-width: 767.98px) {
  .sidebar-backdrop {
    /* background: #0B0B0B url(./assets/images/background.png); 
    background-size: cover; */
    background-color: #0B0B0B !important;
    opacity: 0.7 !important;
  }
}

.card {
  background-color: #181818 !important;
  border: none !important;
  /* padding: 5px 20px; */
  border-radius: 15px !important;
}

.card .card-header {
  border-radius: 15px 15px 0px 0px !important;
  border-bottom: none !important;
  background-color: #262626;
}

/* Form CSS */

input.form-control,
select.form-control,
textarea.form-control,
input.form-control:focus,
select.form-control:focus,
textarea.form-control:focus {
  background-color: #262626;
  border-radius: 5px;
  outline: none;
  width: 100%;
  border: none;
  padding: 10px;
  color: #fff;
  outline: none;
  box-shadow: none;
  margin-bottom: 15px;
  font-size: 14px;
}




.form-control:disabled {
  background-color: #262626 !important;
  color: var(--white-text) !important;
  opacity: 1;
}

form label {
  color: var(--grey-text);
  font-size: 14px;
  /* font-weight:100; */
  margin-bottom: 3px;
}

.form-header p,
.table-header p {
  font-size: 15px;
}



.custom-input-filter-dropdown {
  background-color: #262626 !important;
  color: white !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 8px;
  padding: 14px 12px;
  min-width: 150px;
  text-align: center;
  cursor: pointer;
}

.filter-dropdown {
  background-color: #262626 !important;
  color: white !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 8px;
  /* padding: 14px 12px; */
  min-width: 150px;
  text-align: center;
  cursor: pointer;
}

/* Ensures selected text is white */
.filter-dropdown .p-dropdown-label {
  color: white !important;
}

/* Ensures dropdown options are also white */
.filter-dropdown .p-dropdown-item {
  background-color: #262626 !important;
  color: white !important;
}

/* Hover effect for options */
.filter-dropdown .p-dropdown-item:hover {
  background-color: #6a00ff !important;
  color: white !important;
}

/* Keeps dropdown trigger icon white */
.filter-dropdown .p-dropdown-trigger {
  color: white !important;
}

/* Prevents outline/focus styling */
.filter-dropdown:focus,
.filter-dropdown:active,
.filter-dropdown:focus-within {
  outline: none !important;
  box-shadow: none !important;
}

/* Active (highlighted) dropdown button */
.filter-dropdown.active {
  background-color: #6a00ff !important;
}

.sort-button {
  background-color: #262626 !important;
  color: white !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 8px;
  padding: 10px 15px;
  min-width: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 6px; /* Space between icons and text */
  justify-content: center;
  font-size: 14px;
}

/* Ensures both icons are properly aligned */
.sort-button .pi {
  color: white !important;
  font-size: 14px;
  display: flex;
  align-items: center;
}

/* Adjust vertical alignment for the arrow icon */
.arrow-icon {
  font-size: 12px;
  position: relative;
  top: 1px; /* Pushes the arrow slightly down */
}

/* Sort icon stays normal */
.sort-icon {
  font-size: 14px;
}

/* Hover effect */
.sort-button:hover {
  background-color: #333333 !important;
}


.action-buttons {
  display: flex;
  gap: 8px; /* Space between buttons */
  align-items: center; /* Ensures vertical alignment */
}

.primary-btn {
  background-color: #fff !important;
  color: #000 !important;
  font-size: 11px !important;
  font-weight: 600 !important;
  border-radius: 15px !important;
  padding: 10px !important;
}

.primary-btn img {
  width: 18px;
}

.img-container>label:nth-child(2) {
  width: 100% !important;
  min-width: 100% !important;
}

.max-btn {
  height: 40px;
  font-size: 0.875rem;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1;
}

/* Table CSS */

.search-container input[type=search]::placeholder {
  color: #fff;
}

.table-responsive {
  background-color: #181818 !important;
}

.table-responsive table {
  border: none !important;
}

.table-responsive table thead th {
  background-color: #262626;
  border: none !important;
  color: var(--white-text);
  padding: 0px 20px 15px 20px;
  font-weight: 100;
}

.table-responsive table tbody tr {
  border-bottom: 1.5px solid #262626;
}

.table-responsive table tbody p {
  margin-bottom: 0px !important;
}

.table-responsive table tbody td {
  background-color: transparent !important;
  padding: 5px 20px;
  color: var(--white-text);
  font-size: 14px;
  font-weight: 100;
}

@media screen and (max-width: 771px) {

  .table-responsive table thead th,
  .table-responsive table tbody td {
    font-size: 13px;
  }
}

/* Pagination CSS */
.p-paginator {
  background: #181818 !important;
  border-radius: 0px 0px 15px 15px !important;
}

.p-paginator .p-dropdown {
  background: #262626;
  border: none;
  box-shadow: none !important;
}

.p-paginator .p-dropdown .p-dropdown-label {
  color: var(--white-text) !important;
}

.p-dropdown-panel .p-dropdown-items {
  background: #181818;
  border-radius: 7px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.75rem 1.25rem;
  border: 0 none;
  color: var(--grey-text) !important;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--white-text) !important;
  background: var(--primary-purple) !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: transparent !important;
  border-color: transparent !important;
  color: var(--white-text) !important;
}

/* Chips CSS */
.p-chips {
  display: block !important;
  width: 100% !important;
  background-color: #262626 !important;
  border: none !important;
}

.p-chips .p-chips-multiple-container {
  padding: 0px !important;
  background-color: #262626 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: var(--white-text) !important;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: "Montserrat" !important;
  font-size: 1rem;
  color: var(--white-text) !important;
  padding: 0;
  margin: 0;
}

/* Multi Select CSS */

.p-multiselect-panel {
  background: #262626 !important;
  color: var(--white-text) !important;
  border: 0 none;
  border-radius: 6px;
  box-shadow: none !important;
}

.p-multiselect {
  background: #262626 !important;
  border: none !important;
  transition: none !important;
  border-radius: 6px;
  box-shadow: none !important;
}

.p-multiselect-panel .p-multiselect-header {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #262626 !important;
  color: var(--grey-text) !important;
  background: #262626 !important;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-multiselect-panel .p-multiselect-header .p-inputtext {
  font-family: "Montserrat" !important;
  font-size: 1rem;
  color: var(--white-text) !important;
  background: #181818 !important;
  padding: 0.55rem 0.75rem;
  border: 1px solid #181818 !important;
  transition: none !important;
  appearance: none;
  border-radius: 6px;
  box-shadow: none !important;
}

/* 
.p-checkbox .p-checkbox-box {
  border: 2px solid #181818 !important;
  background: #181818 !important;
  width: 22px;
  height: 22px;
  color: #495057;
  border-radius: 6px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
} */

.p-multiselect-label {
  color: var(--white-text);
  padding: 0.55rem 0.75rem !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #4338CA;
  background: #262626 !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  color: var(--white-text) !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:hover {
  background-color: #181818 !important;
}


/* Accordion CSS */
.p-accordion-header,
.p-accordion .p-accordion-header .p-accordion-header-link,
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background-color: #262626 !important;
  border: none;
  border-radius: 5px !important;
  border-bottom: 1px solid #909090 !important;
  box-shadow: none !important;
}

.p-accordion .p-accordion-content {
  background-color: #262626 !important;
  color: var(--white-text) !important;
  border: none !important;
}

/* Tab CSS */
.p-tabview .p-tabview-nav {
  background: #181818 !important;
  border: 1px solid #181818 !important;
  border-radius: 15px 15px 0px 0px;
}

.p-tabview .p-tabview-nav li {
  border: none;
  background: transparent !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: none !important;
  /* border-color: transparent transparent #181818 transparent; */
  background: #181818 !important;
  color: #909090 !important;
  padding: 1.25rem;
  font-weight: 500;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #6A0BFF !important;
  /* border-bottom: 2px solid #ff0b50 !important; */
  /* border-color: #6A0BFF !important; */
  color: var(--white-text) !important;
}

.p-tabview .p-tabview-panels {
  background: #181818 !important;
  /* padding: 0px !important; */
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:hover {
  background-color: #6A0BFF !important;
}

/* Loader CSS */
.table-responsive .loader {
  position: relative;
  /* left: 50%; */
  /* top: 50%; */
  /* transform: translate(261%, 0%); */
  height: 100%;
  margin: 50px auto;
}

.table-responsive .loader>div>svg {
  background-color: transparent !important;
}

.table-responsive .mapped-table .loader {
  position: relative;
  /* left: 50%;
  top: 50%;
  transform: translate(125%, 0%) !important; */
  height: 100%;
  margin: 50px auto;
}

.loader {
  /* margin: 100px auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.loader>div {
  /* align-content: center; */
  justify-content: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  -ms-touch-action: none;
  touch-action: none;
}

/* Common Mobile Resposive CSS */

@media screen and (max-width: 771px) {
  form div.red-text {
    font-size: 13px;
  }

  button {
    font-size: 12px !important;
  }
}

.hover-text:hover {
  color: white !important;
  cursor: pointer !important;
}


.custom-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.custom-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 25px;
}

.custom-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3.5px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .custom-slider {
  background-color: #6200EA;
}

input:checked + .custom-slider:before {
  transform: translateX(24px);
}


/* Custom Upload Box */
.notif-upload-container {
  width: 100%;
  height: 300px;
  background-color: #262626;
  border: 2px dashed #666;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
  overflow: hidden;
  position: relative;
}

/* Remove border when image is uploaded */
.notif-upload-container.notif-no-border {
  border: none;
}

/* Remove FileUploader border */
.notif-file-uploader {
  border: none !important;
  outline: none !important;
}

/* Upload Icon Styling */
.notif-upload-icon {
  /* width: 50px;
  height: 50px; */
  margin-bottom: 10px;
}

/* Image Preview - Fully Fits Container */
.notif-preview-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

/* Uploaded Image */
.notif-preview-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

/* Cross (Remove) Button */
.notif-remove-btn {
  font-size: 14px;
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  color: white;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
}

.notif-remove-btn:hover {
  background: rgba(0, 0, 0, 0.8);
}


.custom-event-form-image-upload-section {
  width: 100%;
  margin-bottom: 20px;
}

.custom-event-form-large-upload-box {
  width: 100%;
  height: 170px;
  border: 2px dashed #aaa;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  background: #222;
  overflow: hidden;
  transition: border 0.3s ease-in-out;
}

/* Remove border when an image is uploaded */
.custom-event-form-no-border {
  border: none !important;
}

/* Small Upload Boxes for Tile & Web Ticket Images */
.custom-event-form-small-upload-box {
  flex-shrink: 0; /* Prevents shrinking */
  width: 80px;
  height: 80px;
  border: 2px dashed #aaa;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
}

/* Preview Container */
.custom-event-form-preview-container {
  width: 100%;
  height: 170px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #222;
  border: none; /* Remove border for consistency */
}

/* Ensure uploaded image fits the container */
.custom-event-form-preview-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}


/* Scrollable Container for Multiple Images */
.custom-event-form-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  padding-top: 10px;
  display: flex;
  align-items: center; /* Ensures upload box & images are aligned */
}

/* Image Preview Grid for Scrolling */
.custom-event-form-preview-grid {
  display: flex;
  flex-wrap: nowrap; /* Prevents wrapping */
  gap: 10px;
  align-items: center; /* Keeps everything inline */
}



/* Individual Image Preview Box */
.custom-event-form-small-preview-box {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0; /* Prevents resizing */
}


/* Upload Icon */
.custom-event-form-upload-icon {
  /* width: 40px;
  height: 40px; */
}

/* Remove Button */
.custom-event-form-remove-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Remove Default FileUploader Border */
.custom-event-form-file-uploader {
  border: none !important;
  background: transparent !important; /* Ensures no background color */
  outline: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Hide the default date picker icon */
/* input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
} */

/* Custom input styles */
/* .input-wrapper input {
  padding-left: 2rem; 
} */

/* Optional: Add some styles to make the icon look better */
/* .input-wrapper span {
  font-size: 1.2rem; 
  color: #555;      
} */


.custom-user-profile-modal{
  /* inset: 80px !important; */
  /* margin: 0 auto; */
}

